//media mixins

@mixin bp($point) {
	@if $point == desktop {
		@media (min-width: 769px) {
			@content;
		}
	} @else if $point == medium-screen {
		@media (max-width: 1100px) {
			@content;
		}
	} @else if $point == tablet {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $point == mobile {
		@media (max-width: 767px) {
			@content;
		}
	}
}

.navbar {
	padding: 0px;
	margin: 0px;
	box-shadow: none;
	.navbar-nav {
		display: block;
		width: 100%;
		.nav-link {
			color: #7c7f81;
			font-size: 16px;
			border-bottom: 1px solid #7c7f81;
			padding: 15px 0px;

			span:first-child {
				font-family: "PorscheNext-Bold";
			}

			span:last-child {
				font-family: "PorscheNext-Regular";
				margin-left: 8px;
				display: inline-block;
			}

			&.active {
				color: #fff;
				&:hover {
					color: #fff;
				}
				span {
					color: #e00000;
				}
			}
			&:hover {
				color: #ffffff;
				span:first-child {
					color: #e00000;
				}
			}
			&.active-link {
				span:first-child {
					color: #e00000;
					font-family: "PorscheNext-Regular";
				}
				span:last-child {
					font-family: "PorscheNext-Bold";
				}
			}
		}
		.nav-item {
			&:last-child {
				.nav-link {
					border-bottom: none;
				}
			}
		}
		@include bp(tablet) {
			height: calc(100% - 85px);
			overflow-y: auto;
		}
	}
}
.navigation-color {
	background: #323639;
}
.navigation-link {
	line-height: 75px;
	margin-top: 10%;
}
.navigation-link a {
	color: gray;
	text-decoration: none;
}

tr:first-child ::first-line {
	color: red;
}
.navigation-content-detail-height {
	height: 100vh;
	position: relative;
	top: 0px;
	bottom: 0px;
}
.asideBar {
	top: 0;
	z-index: 999 !important;
	display: flex;
	align-items: center;
	background: none;
	&.left-panel-bar {
		padding: 0;
		position: fixed;
		height: 100%;
		.navbar-collapse {
			padding-top: 100px;
		}
	}
	// @include bp(tablet) {
	//   width: 50%;
	// }
	// @include bp(mobile) {
	//   width: 100%;
	// }

	// box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.75);
}
@include bp(tablet) {
	.navigation-link {
		margin-top: 50%;
		margin-left: -10%;
	}
	.navigation-link {
		padding-left: -5% !important;
		overflow: scroll;
	}
}

@include bp(tablet) {
}
.nav-footer {
	height: 48px;
	padding: 15px 42px 10px 42px;
	position: absolute;
	bottom: 0px;
	box-shadow: 0px -5px 8px 0px rgba(0, 0, 0, 0.75);
	display: block;
	left: 0px;
	width: 100%;
	background: #323639;
	ul {
		margin: 0px;
		padding: 0px;
		li {
			display: inline-block;
			color: #979797;
			padding-top: 0px;
			padding-right: 21px;

			cursor: pointer;
			&:hover {
				color: #4a4e51;
			}
			a {
				.fa {
					font-size: 20px;
					vertical-align: middle;
				}
				span {
					font-size: 16px;
					margin-left: 12px;
					vertical-align: top;
					line-height: 19px;
				}
			}
		}
	}
	@include bp(medium-screen) {
		padding: 15px 25px 10px 25px;
	}
	@include bp(tablet) {
		bottom: 40px;
		padding: 15px 25px 10px 25px;
	}
}
.active-link {
	color: #fff !important;
}
@include bp(tablet) {
	.nav-show {
		width: 100%;
	}
}
.navbar-collapse {
	width: 332px;
	padding-top: 132px;
	height: 100%;
	display: flex;
	align-items: flex-start !important;
	padding-left: 30px;
	padding-right: 30px;
	@include bp(medium-screen) {
		width: 277px;
		padding-left: 20px;
		padding-right: 20px;
	}
}
.nav-show {
	.navbar-toggler {
		.icon-close {
			display: block;
		}
		.icon-menu {
			display: none;
		}
	}
}
.navbar-toggler {
	position: absolute;
	top: 0px;
	z-index: 2;
	padding: 8px 24px;
	.icon-menu {
		display: block;
	}
	.icon-close {
		display: none;
	}
	.navbar-toggler-icon {
		margin-top: 4px;
		margin-top: 8px;
		font-size: 12px;
		color: #000;
	}
}
@include bp(tablet) {
	.navbar-collapse {
		position: absolute;
		top: 43px;
		right: 100%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
		width: 100%;
		transition: all 0.3s ease;
		display: block;
		padding-top: 43px;
	}
	.navbar-collapse.collapsing {
		height: auto !important;
		margin-right: 50%;
		transition: all 0.3s ease;
		display: block;
	}
	.navbar-collapse.show {
		right: 0;
		position: fixed;
	}
}
.dropdown-navbar {
	display: none;
}
@include bp (tablet) {
	.left-panel-bar {
		position: fixed !important;
		height: auto !important;
		top: 70px;
		width: 100%;
		.dropdown-navbar {
			width: 100%;
			display: block;
			.dropselect {
				background: #323639;
				padding: 15px 24px;
				font-family: "PorscheNext-Regular";
				color: #ffffff;
				cursor: pointer;
				.select-num {
					font-family: "PorscheNext-Bold";
					color: #D5001C;
					font-weight: 600;
					margin-right: 15px;
				}
				.drop-icon {
					position: absolute;
					color: #ffffff;
					font-size: 8px;
					right: 24px;
					top: 24px;
					&.icon-rotate {
						transform: rotate(180deg);
						transition: all 0.5s;
					}
				}
			}
			.dropdown-content {
				display: none;
				position: absolute;
				width: 100%;
				background: #323639;
				&.show {
					display: block;
					z-index: 1;
				}
				&.show + .background-drop {
					position: fixed;
					top: 124px;
					width: 100%;
					height: calc(100% - 124px);
					background: #000000;
					opacity: 0.5;
				}
				.navbar-nav {
					.nav-item {
						a {
							&.nav-link {
								padding: 15px 24px;
								border-bottom: none;
								display: flex;
							}
							&.active-link {
								color: #fff !important;
								background-color: #000000;
							}
						}
					}
				}
			}
		}

	}
}