@import "../../../css/font.scss";
@import "../../../css/mixins.scss";
@import "../../../css/typography.scss";

.baricon-image{
    width: 24px;
    height: auto;
    position: relative;
    top: 7px;
}
.bar-block-modal {
    .modal-dialog {
        max-width: 990px;
        @include bp(mobile) {
            max-width: 95vw;
        }
        .modal-header {
            border-bottom: none;
            position: relative;
            padding: 35px 40px 30px 40px;
            .modal-title {
                font-size: 64px;
                font-weight: 700;
                color: #000000;
                line-height: 64px;
                @include bp(tablet) {
                    font-size: 24px;
                    font-weight: 700;
                    color: #000000;
                    line-height: 32px;
                }
            }
            @include bp(tablet) {
                padding: 40px 24px 40px 24px;
            }
            .close {
                position: absolute;
                top: 20px;
                right: 20px;
                z-index: 1;
                opacity: 1;
            }
        }
        .bar-block-modal-container {
            padding: 0 40px 40px 40px;
            .row {
                margin: 0;
            }
            .text-block {
                padding: 0;
                padding-left: 40px;
                @include bp(mobile) {
                    padding-left: 0;
                    margin-top: 30px;
                }
                p{
                    white-space: break-spaces;
                }
            }
            @include bp(tablet) {
                padding: 0 24px 40px 24px;
                max-width: 100%;
            }
            .image-block {
                padding: 0;
                .img-fluid {
                    width: 100%;
                    object-fit: cover;
                    height: 240px;
                    @include bp(mobile) {
                        height: 204px;
                    }
                }
                .bar-image-link {
                    a {
                        display: flex;
                        align-items: flex-start;
                    }
                }
            }
            .bar-image-link {
                span {
                    color: #000000;
                    font-size: 18px;
                    line-height: 30px;
                    text-decoration: underline;
                    position: relative;
                    top: 5px;
                    margin-left: 10px;
                }
                &.maplinkrow {
                    .baricon-image {
                        position: relative;
                        top: 8px;
                    }
                }
                a {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}