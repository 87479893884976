.paragraph-text{
    text-align: left;
    color: black;
    margin-left: 332px;
}


.key_heading a{
    color: #000000;
   text-decoration: underline;
}
.key_heading a:hover,.key_heading a:focus{
    color: #000000;
    text-decoration: underline;
   }
   //for role C page 5
   .key_headings a{
    color: #222 !important;
    // font-weight: 600;
    text-decoration: underline;
}
.key_headings b, strong {
    color:#696c6e;
    font: 26px PorscheNext-Bold;
}
.key_headings a:hover,.key_headings a:focus{
    color: #000;
   }