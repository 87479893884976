@mixin bp($point) {
	@if $point == desktop {
	  @media (min-width: 769px) { @content; }
	}
	@else if $point == medium-screen {
	  @media (max-width: 1200px) { @content; }
	}
	
	@else if $point == tablet {
	  @media (max-width: 991px) { @content; }
	}
	@else if $point == mobile {
	  @media (max-width: 767px)  { @content; }
	}
  }
.sticky-footer{
   width: 100%;
   margin-top: -16px;
}
footer {
   &.footer {
      position: relative;
      bottom: 0;
      width: 100%;
      z-index: 999;
      left: 0;
      padding: 0 60px;
      height: 75px;
      @include bp(mobile) {
         height: auto;
         padding: 0 24px;
      }
   }
}