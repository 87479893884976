//media mixins

@mixin bp($point) {
	@if $point == desktop {
	  @media (min-width: 769px) { @content; }
	}
	@else if $point == medium-screen {
	  @media (max-width: 1200px) { @content; }
	}
	
	@else if $point == tablet {
	  @media (max-width: 991px) { @content; }
	}
	@else if $point == mobile {
	  @media (max-width: 767px)  { @content; }
	}
  }

.sticky-inner {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: white;    
}
.header{
  position: fixed;
  z-index: 3 !important;
  background-color: white;
  height: 75px;
  top: 0px;
}
.logo-box {
  height: 100%;
}
.logo {
  height: 100%;
}
.header-text-pd-tp{
  padding-top: 37px;
  @include bp(tablet){
    padding-top: 13px;
  }
}
.header-left{
    font:20px 'PorscheNext-Regular';
    color: #000000;
    padding-left: 40px;
    padding-right: 40px;
    background: #fff;
    font-weight: 600;
    width: 100%;
    position: absolute;
    height: 75px;
    top: 0px;
    left:0px;
    @include bp(medium-screen){
      padding-left: 20px;
      padding-right: 20px;
    }
    @include bp(tablet){
      font:16px 'PorscheNext-Bold';
      height: 43px;
      background: #626669;
      color: #fff;
    } 
}
.header-left span{
   color: #000000;
   font-weight: 600;
   font-family: 'PorscheNext-Regular';
} 

.header-right{
  font-size: 16px;
  color: #000000;
  margin-top: 7px;
}
.header-right span{
 color: #C9CACB;
 display: inline-block;
 padding: 0px 10px;
 cursor: pointer;
} 

.header-right span .prev-next-icon{
  padding: 0px 5px;
  font-size: 12px;
}


/*responsive*/

@include bp(tablet){
.header {
  height: 70px;
}
.logo{
  width: auto;
  margin-top:0px;
  min-height: 42px;
}
.header .header-left{
  display: none;
}
.header-right{
  margin: 0px -15px;
  padding: 11px 10px 7px 10px;
  text-align: center !important;
  background: #E3E4E5;
  
}
.header-right {
  .f-left{
    float: left;
    position: absolute;
    left: 0px;
  }
  .f-right{
    float: right;
    position: absolute;
    right: 0px;
  }
}

.nav-icon{margin-top: 10px;}
}
@media only screen and (min-width: 600px) {
.nav-icon{display: none;}

}
