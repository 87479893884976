//media mixins

@mixin bp($point) {
	@if $point == desktop {
	  @media (min-width: 769px) { @content; }
	}
	@else if $point == medium-screen {
	  @media (max-width: 1200px) { @content; }
	}
	
	@else if $point == tablet {
	  @media (max-width: 1000px) { @content; }
	}
	@else if $point == mobile {
	  @media (max-width: 767px)  { @content; }
	}
  }


.content-navigation-container-div {
  display: inline-block;
  min-height: 500px;
  width: 80%;
  padding: 0px !important;
  background-color: white;
  margin-left:18%
}
.content-navigation-container-div img {
  padding: 0px !important;
}

.content-navigation-container-div .navigation-menu {
  color: "#e3e4e5";
  background-color: #626669;  
  padding: "10px";
  font-family: 'Arial';
  width: 20%;
  height: inherit;
}


.content-detail .welcomeFooter {
  width: 100%;
  height: 5%;
  background-color: black;
}

.welcome-content-detail {
  height: auto;
  // color: white;
  /* background-color: black; */
  /* background-image: linear-gradient(180deg, black, gray); */
  position: relative;
}
.paragraph-header{
  padding: 0px 0px 0px 120px;
}
// .content-detail h3 {
//   font-size: 20px;
//   padding: 0px 0px 0px 220px;
// }

// div.content-detail div + h2 {
//   color: black;
//     margin: 23% 0% 0% 0%;
//     width: 100%;
//     padding: 0px 100px;
// }

.content-header-1{
  padding: 0px 0px 0px 220px;
}
.content-paragraph-1, .content-paragraph-2, .content-paragraph-3, .content-paragraph-4{
  display: block;
  width: 60%;
  text-align: left;
  padding: 0px 0px 0px 220px;
  font-size: 15px;
}
.content-header-2{
  font-size: 20px;
  padding: 45px 0px 0px 220px;
}
.person-img{
  vertical-align: middle;
   width: 47px;
}
.content-paragraph-6{
  display: block;
  color: gray;
}
.link-tag{
  margin-left: 20%;
  color: black !important;
}
.per-img-text{
  padding: 0px 0px 0px 220px;
}
.carousel-header-1, .carousel-header-2, .carousel-header-3{
  margin-left: 3rem;
  text-align: left;
  width: 90%;
}
.content-img-header{
  margin: -5% 0% 0% 50%;
}
.content-img{
  height: 40%;
  margin: -22% 0% 0% 4%;
  width: 40%;
}
.contacts{
  margin: -11% 0% 0% 50%;
}
.content-img-paragraph{
  width: 40%;
    margin: 0% 0% 0% 50%;
}
.img-background{
  width: 100%;
    height: 1150px;
  margin: 14% 0% 0%;
  background-color: rgb(224, 224, 224);
}
.hello-text{
  background-color: #D5001C;
    border-color: #D5001C;
    position: relative;
    color: white;
    padding: 12px;
    text-decoration: none;
    margin: 10% 0% 0% 50%;
}
.hello-text-div a::before{
  content: url(../images/arrow-right-hair.png);
  padding-right: 10px;
} 
.document-header{
  color: white;
  padding: 56px 1px 1px 60px;
}
.document-text{
  padding: 8px 1px 1px 65px;
  text-align: left;
  color: white;
}
.document-img{
  margin: 17% 0% 0% 8%; 
  width: 80%;
  height: 500px;
  object-fit: contain;
  background-size: cover;
}
.text-header{
  margin: 150px 10px 10px 8% !important;
    width: 40% !important;
    color: black !important;
    display: block;
}
.text-paragraph{
  margin: -16% 10% 10% 37%;
    display: block;
    width: 50%;
    padding: 0px 0px 0px 220px;
    /* position: absolute; */
    font-size: 26px !important;
}
.text-href{
  margin: -9% 10% 10% 55%;
    display: block;
    width: 40%;
    font-weight: 500;
    color: black !important;
    
}
.event-text{
  color: black;
    width: 80%;
    display: block;
    margin: 80px 10px 10px 8% !important;
}
.image-bkg{
  width: 75%;
  display: inline-flex;
  height: 200px;
  margin: 10px 0px 0px 8%;
}
.note-img{
  margin: 70px 0px 0px 99px;
}
.event-img{
  width: 35%;
  align-items: flex-start;
}
.event-image-bkg{
  width: 70%;
  background-color: rgb(240, 240, 240);
}
.event-header{
  padding: 10px 0px 0px 50px;
  color: red !important;
}
.event-paragraph{
  color: black; 
  padding: 0px 0px 0px 50px;
}
.note-paragraph{
  padding: 0px 10px 0px 50px;
}
.image-bkg{
  width: 75%;
  display: inline-flex;
  height: 200px;
  margin: 10px 0px 0px 8%;
}
.note-img{
  margin: 70px 0px 0px 99px;
}
.event-img-2{
  width: 30%;
    background-color: rgb(224, 224, 224);
}
.event-image-bkg-2{
  width: 70%;
  background-color: rgb(240, 240, 240);
}
.event-header-2{
  padding: 10px 0px 0px 50px;
  color: red !important;
}
.event-paragraph-2{
  color: black; 
  padding: 0px 0px 0px 50px;
}
.note-paragraph-2{
  padding: 0px 10px 0px 50px;
}
.image-bkg-2{
  width: 75%;
  display: inline-flex;
  height: 200px;
  margin: 10px 0px 2% 8%;
}
.note-img-2{
  margin: 70px 0px 0px 99px;
}
.carousel-heading{
  width: "90%"
}

.welcome-header-image{
  height: auto;
  margin-left: -6px;
}
.video-icon {
    width: 36px;
    cursor: pointer;
    vertical-align: top;
    display: inline-flex;
    height: 36px;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #D5001C;
    border-radius: 50%;
    text-align: center;
    .icon-play{
      margin-left: 3px;
    }
    
     @include bp(mobile){
      background: #E3E4E5;
      width: 24px;
      height: 24px;
      .icon-play{
        font-size: 10px;
      }
    }
}

.more-dtl{
  font-size: 28px;
  line-height: 24px;
  margin-top: 6px;
  cursor: pointer;
  @include bp(mobile){
    font-size: 16px;
    margin: 0px;
  }
}
.content-header-background {
  background-color: #000000;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.35;
  z-index: 1;
}
.content-header-img {
  color: white;
  position: absolute;
  width: 100%;
  z-index: 1;
  bottom: 20px;
  @include bp(mobile) {
    bottom: 0px;
  }
  h2 {
    font-size: 84px;
    font-weight: 600;
    margin-bottom: 0px;
    line-height: 92px;
    @include bp(mobile){
      font-size: 42px;
      line-height: 52px;
    }
  }
  @include bp(mobile){
    bottom: 20px;
  }
}

.grid-img {
  width: inherit;
  height: 356px;
}
// .slider-wrapper{
//   // margin-left: 11% !important;
// }
.carousel-img-1{
   object-fit: contain; 
   background-size: cover; 
   height:100%;
}
.carousel-img-1-div{
  float: left;
  margin:60px 0px 0px 0px;
  color:white;
   height:500px
}
.carousel-img-1 h2{
  text-align:left; 
  width:90%;
  margin-left: 3rem;
}
.carousel-1-link{
  margin-left: 3rem;
  text-align:left;
  width: 90%;
  display:block;
}
.carousel-img-2 {
  object-fit: contain; 
   background-size: cover; 
   height:100%;
}
.carousel-img-2-div {
  float: left;
  margin:60px 0px 0px 0px;
  color:white;
  height:500px;
}
.carousel-img-2 h1{
  margin-left: 3rem;
  text-align:left;
   width:90%;
}
.carousel-img-3{
  object-fit: contain; 
   background-size: cover; 
   height:100%;
}
.carousel-img-3-div{
  float: left;
  margin:60px 0px 0px 0px;
  color:white;
  height:500px;
}
.carousel-img-3 h1{
  margin-left: 3rem;
  text-align:left;
   width:90%;
}
// h2:nth-child(10)   {
//   padding: 1px 0% 0% 41%;
//     width: 100% !important;
// }
// div:nth-child(11)   {
//   margin-top: -7% !important;
// }

div:nth-child(12)   {
  color: #D5001C !important;
}
div:nth-child(17)   {
color: black;
}
.color-white{
  color: #fff;;
}
tr:first-child ::first-line {
  color: black !important;
  font-weight: normal;
}

.col-sm-6.just-say-sec .PorscheTeam {
  padding-left: 0px !important;
}

.fantasy{
  display: flex;
  margin-top: 85px;
  *{
    flex: 1;
  }
}

.paddingh2{
  padding-left: 100px;
  margin-top: 100px;
  @include bp(medium-screen){
    padding-left: 50px;
  }
  
  @include bp(mobile){
    padding-left: 25px;
  }
  
}
.team-know-more::before {
  color: #D5001C;
  content: "\e901";
  font-family: 'icomoon';
  padding-right: 12px;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
}
