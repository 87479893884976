a:hover,
a:focus {
	outline: none;
	text-decoration: none;
}
#accordion .panel {
	border: 0px none;
	box-shadow: none;
}
#accordion .panel-title a {
	display: block;
	position: relative;
	background: transparent;

	font-size: 14px;
	font-weight: bolder;
	text-transform: uppercase;
	margin-bottom: 15px;

	transition: all 0.1s linear 0s;
}
#accordion .panel-title a {
	border-bottom: 1px solid #d3d3d3;
	margin: 0;
}
#accordion .panel-title a i {
	color: #de4d4e;
	position: absolute;
	top: 14px;
	left: 25px;
}
#accordion .panel-title a:before,
#accordion .panel-title a.collapsed:before {
	content: "";
	position: absolute;
	bottom: -15px;
	left: 36px;
	border: 7px solid transparent;
}
#accordion .panel-title a.collapsed:before {
	content: "";
	border: 0px none;
}
#accordion .panel-title a.collapsed:hover {
	color: #6a6060;
}
#accordion .my-according-color:after {
	background-color: red !important;
}
#accordion .panel-title a:after,
#accordion .panel-title a.collapsed:after {
	content: "\e907";
	font-family: "icomoon";
	color: white;
	font-size: 20px;
	line-height: 20px;
	position: absolute;

	left: 0px;
	right: 0px;
	margin: 0px auto;
	background-color: red;
	border-radius: 50%;
	font-size: 7px;
}
#accordion .panel-title a.collapsed:after {
	content: "\e906";
	font-family: "icomoon";
	color: white;

	background-color: black;

	border-radius: 50%;
}
#accordion .panel-body {
	border-top: 0 none;
	color: #7c7f81;
	padding-bottom: 20px;
}

.accordion-group {
	.panel {
		margin-bottom: 35px;
	}
}
#accordion {
	.panel-title {
		margin-bottom: 20px;
		color: #000000;
		text-transform: capitalize;

		a {
			position: relative;
			padding-top: 5px;
			&:after {
				position: absolute;
				bottom: -20px;
				height: 40px;
				width: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}
.accordion-card {
	margin-bottom: 20px;
	.img-fluid {
		width: 100%;
	}
}
.links {
	font: 16px "PorscheNext-Regular";
	color: #000000;
	display: inline-block;
	margin-top: 10px;
	.fa {
		color: #d5001c;
		font-size: 23px;
		vertical-align: top;
		margin-right: 4px;
		margin-top: -2px;
	}
}
