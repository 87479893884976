.heading{
    font-size: 64px;
    line-height: 57.6px;
    font-family: 'PorscheNext-Regular';
    color:  #D5001C;
    font-weight: 700;
}
.text{
    font-size: 18px;
    line-height: 30px;
    font-family: 'PorscheNext-Regular' ;
    
}