//   typography
.heading-2{
	font: 64px "PorscheNext-Bold";
	line-height: 64px;
	@include bp(mobile) {
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 0;
	}
}

.heading-3{
	 font: 32px 'PorscheNext-Bold';
	 color: #000000;
	 line-height: 40px;
	 margin-bottom: 0px;
	 @include bp(mobile) {
		font-size: 24px;
		line-height: 32px;
	}
}
.heading-4{
	font-size: 28px;
	@include bp(mobile) {
		font-size: 16px;
		line-height: 24px;
	}
}

.heading-5{
	font-family: PorscheNext-Bold;
	@include bp(mobile) {
		font-size: 16px;
	}
}

.primary-color{
	color: #D5001C;
}
.praghaph-3{
	font: 18px 'PorscheNext-Regular';
	color: #7C7F81;
	@include bp(mobile) {
		font-size: 16px;
		line-height: 24px;
	}
}
.praghaph-bold{
	font: 18px 'PorscheNext-Bold';
	@include bp(mobile) {
		font-size: 16px;
		line-height: 24px;
	}
}