@mixin bp($point) {
	@if $point == desktop {
	  @media (min-width: 769px) { @content; }
	}
	@else if $point == medium-screen-desktop {
		@media (max-width: 1250px) { @content; }
	  }

	@else if $point == medium-screen {
	  @media (max-width: 1100px) { @content; }
	}
	
	@else if $point == tablet {
	  @media (max-width: 991px) { @content; }
	}
	@else if $point == mobile {
	  @media (max-width: 767px)  { @content; }
	}
  }