//media mixins

@mixin bp($point) {
	@if $point == desktop {
		@media (min-width: 769px) {
			@content;
		}
	} @else if $point == medium-screen {
		@media (max-width: 1200px) {
			@content;
		}
	} @else if $point == tablet {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $point == mobile {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point == xs-mobile {
		@media (max-width: 600px) {
			@content;
		}
	}
}

.table-value {
	border-top: 1px solid #c9cacb;
	p {
		padding: 12px 0px;
		margin: 0px;
	}
	.table-item-right {
		text-align: right;
		@include bp(xs-mobile) {
			text-align: left;
			padding-top: 0px;
		}
	}
}
.pointer-disabled {
	pointer-events: none;
}
.pageItems {
	.table-value {
		&:last-child {
			border-bottom: 1px solid #c9cacb;
		}
	}
}
.personImage {
	margin-top: 36%;
	margin-left: -8px;
}
.table-item {
	font-family: "PorscheNext-Bold";
	color: #000;
	@include bp(xs-mobile) {
		padding-bottom: 0px !important;
	}
}

.card-img {
	width: 100%;
	margin: 8% 0% 5% 0%;
}
.img-header {
	color: red;
	margin-left: 3%;
}

.img-linktext {
	color: white;
	// margin-left: 3%;
}
.title-1 {
	font-size: 2em;
	font-weight: 600;
	@include bp(mobile) {
		font-size: 24px;
		line-height: 32px;
	}
}
.seperator {
	display: flex;
	width: 100%;
	border-bottom: 1px solid #c9cacb;
	margin: 30px 0;
	@include bp(mobile) {
		margin: 15px 0;
	}
}	
.menuHeadingmargin {
	margin-bottom: 45px;
	@include bp(mobile) {
		margin-bottom: 15px !important;
 	}
}
// .hrmobview {
// 	@include bp(mobile) {
// 		display: none;
//  	}
// }
#Kantine,#Betriebssport-undFreizeitgruppen {
	@include bp(mobile) {
		margin-bottom: 50px !important;
 	}
}
#Mitarbeiterausweis {
	@include bp(mobile) {
		margin-bottom: 20px !important;
 	}
}
.btm-acr-container {
	@include bp(mobile) {
		margin-top: 40px;
		& + .hrseptop {
			display: none;
		}
	}
}
.top-section-breadcrumb {
	padding: 0 60px;
	@include bp(medium-screen) {
		padding-left: 24px;
		padding-right: 24px;
	}
	@include bp(mobile) {
		padding-left: 24px;
		padding-right: 24px;
	}
	&.headingmainpage {
		margin-top: 45px;
		@include bp(mobile) {
			margin-top: 25px;
		}
	}
	#breadcrumbmenu {
		.breadcrumb {
			padding-left: 0;
			margin: 0px;
		}
		.breadcrumb-item {
			a {
				color: #000000;
				font-size: 18px;
				text-decoration: underline;
				background-color: transparent;
				@include bp(mobile) {
					font-size: 16px;
				}
			}
			&:before {
				content: "\276F";
				position: relative;
				top: 5px;
				padding-right: 0.5em;
				font-size: 12px;
			}
			padding-right: 20px;
			&:last-child {
				padding-right: 0px;
			}
		}
		.breadcrumb-item + .breadcrumb-item::before {
			content: "\276F";
			position: relative;
			top: 5px;
			font-size: 12px;
		}
		.breadcrumb {
			background: none;
		}
		@include bp(mobile) {
			.breadcrumb-item {
				padding-left: 0;
				width: 100%;
				margin-bottom: 15px;
				&:last-child {
					margin-bottom: 0;
				}
			}

		}
	}
}
@include bp(mobile) {
    html {
        scroll-padding-top: 120px; /* height of sticky header */
    }
}

.card-link{
    color: #000000;
    text-decoration: underline;
    &:not(.disable):hover {
        color: #000000;
        text-decoration: underline;
    }
}
.patrikum-padding-container {
	padding-left: 180px;
	padding-right: 180px;
	@include bp(medium-screen) {
		padding-left: 24px;
		padding-right: 24px;
	}
	@include bp(mobile) {
		padding-left: 24px;
		padding-right: 24px;
    }
    h2{
        margin-bottom: 45px;
        @include bp(mobile) {
            margin-bottom: 30px;
        }
        & + .heading-5 {
            margin-bottom: 20px;	
        }
    }
    .heading-5 {
		line-height: 30px;
		margin-bottom: 11px !important;

        @include bp(mobile) {
            line-height: 24px;	
        }
    }
    .praghaph-partikum {
        margin-bottom: 40px !important;	
        @include bp(mobile) {
            margin-bottom: 25px;
        }
		.hyperlink {
			color: #222 !important;
			font-weight: 600;
			text-decoration: underline;
		}
    }
    .mainHead {
        margin-top: 60px;
        @include bp(mobile) {
            margin-top: 20px;
        }
    }
    .hrseptop {
        padding: 0;
        position: relative;
        left: -120px;
        width: calc(100% + 240px);
        @include bp(tablet) {
            width: 100%;
            left: unset;
        }
    }
}
.berufsContainer {
	h5 {
		color: #000000;
		font: 28px PorscheNext-Bold;
	}
}

