.drop-icon {
    position: absolute;
    color: #000000;
    font-size: 7px;
    right: 10px;
    top: 16px;
    &.icon-rotate {
        transform: rotate(180deg);
        transition: all 0.5s;
    }
}
.card-link{
    color: #000000;
    text-decoration: underline;
    &:not(.disable):hover {
        color: #000000;
        text-decoration: underline;
      }
    
}
.accordion .accordionButton {
    cursor: pointer;
}