@font-face {
	font-family: 'PorscheNext-Regular';
	src: url('../fonts/PorscheNext-Bold.eot');
	src: local('☺'), url('../fonts/PorscheNext-Regular.woff') format('woff'), url('../fonts/PorscheNext-Regular.ttf') format('truetype'), url('../fonts/PorscheNext-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'PorscheNext-Bold';
	src: url('../fonts/PorscheNext-Bold.eot');
	src: local('☺'), url('../fonts/PorscheNext-Bold.woff') format('woff'), url('../fonts/PorscheNext-Bold.ttf') format('truetype'), url('../fonts/PorscheNext-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'icomoon';
	src:  url('../fonts/icomoon.eot?wya3rb');
	src:  url('../fonts/icomoon.eot?wya3rb#iefix') format('embedded-opentype'),
	  url('../fonts/icomoon.ttf?wya3rb') format('truetype'),
	  url('../fonts/icomoon.woff?wya3rb') format('woff'),
	  url('../fonts/icomoon.svg?wya3rb#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  
  .icon-play:before {
	content: "\e905";
	
  }
  .icon-arrow-left:before {
	content: "\e900";
  }
  .icon-arrow-right:before {
	content: "\e901";
  }
  .icon-close:before {
	content: "\e902";
  }
  .icon-logout:before {
	content: "\e903";
	
  }
  .icon-menu:before {
	content: "\e904";
  }

  .icon-arrow-down:before {
	content: "\e906";
  }
  .icon-arrow-up:before {
	content: "\e907";
  }
  .icon-linked-in:before {
	content: "\e908";
  }
  .icon-mail:before {
	content: "\e909";
  }