@mixin bp($point) {
	@if $point == desktop {
	  @media (min-width: 769px) { @content; }
	}
	@else if $point == medium-screen {
	  @media (max-width: 1200px) { @content; }
	}
	
	@else if $point == tablet {
	  @media (max-width: 1000px) { @content; }
	}
	@else if $point == mobile {
	  @media (max-width: 767px)  { @content; }
	}
  }


@media only screen and (max-width: 1300px) {
    .welcome-content-detail h1 {
      margin-left: 10%;
      // font-size: 53px !important;    
    }
    .content-paragraph-1, .content-paragraph-2, .content-paragraph-3, .content-paragraph-4 {
      width: 80%;
      /* margin-left: 5%; */
      font-size: 15px;
      /* padding: 0px 0px 0px 150px; */
    }
    /* .content-detail h3 {
      font-size: 20px;
      margin-left: 4%;
      padding: 0px 0px 0px 150px;
    } */
    .content-header-1{
        font-size: 20px;
      margin-left: 4%;
      padding: 0px 0px 0px 150px !important;
    }
    .content-header-2{
        font-size: 20px;
      margin-left: 4%;
      padding: 0px 0px 0px 150px !important;
    }
    .video-player{
      margin-left: 10%;
      /* margin-top: -2%; */
    }
   
  }
  @media only screen and (max-width: 900px) {
    .video-player{
      margin-left: 11%;
      margin-top: -2%;
    }
   
  }
  @media only screen and (max-width: 950px) {
   /* .carousel.carousel-slider {
       width: 70% !important;
      height: 400px; 
      margin-left: 20% !important;
  }*/
  }
  @include bp(mobile){
    .content-paragraph-1, .content-paragraph-2, .content-paragraph-3, .content-paragraph-4 {
        width: 80%;
        margin-left: -5%;
        font-size: 15px;
        /* padding: 0px 0px 0px 150px; */
      }
    .content-detail p {
      width: 100%;
      // font-size: 15px;
      /* padding: 0px 0px 0px 150px; */
    }
    .content-detail .welcomeFooter {
      width: 100%;
      height: 5%;
      background-color: black;
      // margin-left: 7%;
    }
    
    .welcome-content-detail h1 {
      margin-left: 0%;
      // font-size: 45px !important;
      
    }
    .video-player{
      margin-left: 11%;
    }
    /* .video-icon{
      width: 7%;
    } */
    /* .content-detail h3 {
      font-size: 20px;
      margin-left: 7%;
      padding: 0px 0px 0px 150px;
    } */
    .content-header-1{
        font-size: 20px;
        margin-left: 7%;
        padding: 0px 0px 0px 150px !important;
    }
    .content-header-2{
        font-size: 20px;
        margin-left: 7%;
        padding: 0px 0px 0px 150px !important;
    }
    /* .content-detail h4 {
      font-size: 20px;
      margin-left: 7%;
      padding: 45px 0px 0px 150px;
    } */
    /*.carousel.carousel-slider {
       width: 70% !important;
      height: 341px;
      margin-left: 34% !important;
  } */
  p:nth-child(25) a{
   margin-left: 10%;
  }
  p:nth-child(27) a{
   margin-left: 10%;
   margin-top: 5%;
  }
  }
  @include bp(mobile){
    .welcomeFooter {
      display: flex;
      flex-direction: column;
    }
  }