@import "./css/font.scss";
@import "./css/mixins.scss";
@import "./css/typography.scss";

body {
	margin: 0;
	font-family: "PorscheNext-Regular" !important;
	color: #000000;
	overflow-x: hidden;
}
*:focus {
	outline: none !important;
}
.margin-bottom-40 {
	margin-bottom: 40px;
}
.margin-bottom-0 {
	margin-bottom: 0;
}
.margin-top-40 {
	margin-top: 40px;
}
.pad-lg-rt-0 {
	padding-right: 0px;
	@include bp(mobile) {
		padding-right: 15px;
	}
}

.padding0 {
	padding: 0
}

.parentContainer {
	margin: 0px;
	padding: 0px;
}

.myporscheHeaderText .myporscheTxt {
	color: black;
	font-size: 20px;
	font-weight: bold;
}

.myporscheHeaderText .welcomeGuideTxt {
	color: gray;
	font-size: 20px;
	font-weight: bold;
}

.footerContainer {
	margin: 0px;
	padding: 0px;
	width: 80%;
	height: 70px;
	display: inline-flex;
	background-color: black;
	position: relative;
	left: 0;
	bottom: 0;
}
@include bp(mobile) {
	#root {
		overflow: hidden;
	}
}
.sub-navigation-menu {
	width: inherit;
	height: 25%;
	background-color: #626669;
	position: absolute;
	bottom: 0;
	height: 158px;
	-webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
	-moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
	box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
}
.input-full {
	padding: 10px 15px 8px 15px;
	width: 100%;
}

html,
body {
	height: 100%;
}

.welcomeFooter {
	display: block;
	width: 100%;
	position: relative;
	color: white;
	font-size: 16px;
	font-weight: 500;
	.legal-links {
		float: left;
		a {
			text-decoration: underline;
			color: white;
			font-weight: 500;
			margin-left: 40px;
			&:first-child {
				margin-left: 0px;
			}
		}
		@include bp(mobile) {
			float: unset;
			clear: both;
			width: 100%;
			margin-top: 15px;
		}
		
	}
	.legal-links-right {
		float: right;
		a {
			margin-left: 40px;
			text-decoration: none;
			color: white;
			font-weight: 500;
			&:first-child {
				margin-left: 0px;
			}
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4) {
				margin-left: 25px;
			}
			&.text-legal-right {
				text-decoration: underline;
				@include bp(mobile) {
					position: absolute;
					bottom: 15px;
				}
			}
			&:nth-child(5) {
				@include bp(mobile) {
					margin-left: 0;
					left: 0;
				}
			}
			&:nth-child(6) {
				left:80px;
			}
			img {
				max-height: 16px;
			}
		}
		@include bp(mobile) {
			float: unset;
			clear: both;
			width: 100%;
			margin-top: 25px;
			padding-bottom: 60px;
		}
	}
}
.personImage {
	width: 70% !important;
}

.onboardingPerson {
	display: inline-flex;
}

.carousel.carousel-slider {
	width: 100% !important;
	// height: 500px;
}
.carousel .slide {
	background: none !important;
}
// /* ================================================== */
.h-8 {
	height: 8%;
}

.w-20 {
	width: 20%;
}

.text-2 {
	font-size: 1.4em;
}

.z-index-high {
	z-index: 9999999;
}

.footer {
	background-color: black;
}

h1 {
	font-size: 84px;
}

h3 {
	font-size: 32px;
}

h4 {
	font-size: 28px;
}

h2 {
	font-size: 55px;
}

p {
	font-size: 18px;
	font-weight: 400;
	color: #7c7f81;
	@include bp(mobile) {
		font-size: 16px;
		line-height: 24px;
	}
}

.mt-9 {
	margin-top: 9%;
}

.ml-7 {
	margin-left: 7%;
}

.slider-wrapper {
	margin-left: 11% !important;
}

.anchor-button {
	padding: 16px 27px !important;
	text-decoration: none;
	font-size: 16px;
	line-height: 15px;
	display: inline-block;
	font-family: "PorscheNext-Regular" !important;

	@include bp(mobile) {
		font-size: 12px !important;
		padding: 9px 28px !important;
		line-height: 14px;
	}

	.icon-arrow-right {
		font-size: 12px;
		margin-right: 10px;
		vertical-align: top;
		@include bp(mobile) {
			vertical-align: middle;
		}
	}
	&.icon-arrow-right {
		&:before {
			font-size: 12px;
			margin-right: 10px;
			font-family: "icomoon" !important;
			vertical-align: top;
		}
	}
}

.anchor-button-red {
	background-color: #d5001c;
	border-color: #d5001c;
	position: relative;
	color: #fff !important;
	cursor: pointer;
}
.login-button {
	text-align: left;
	width: 100%;
	border: none;
	@include bp(mobile) {
		padding: 16px 28px !important;
		font-size: 16px !important;
	}
}

.anchor-button-red:hover {
	background-color: #ac091e;
	color: #fff;
}

.anchor-button-gray {
	background-color: #303030;
	border-color: #303030;
	position: relative;
	color: white;
}

.anchor-button-gray:hover {
	background-color: #484848;
	border-color: #484848;
}

.content-area {
	margin-top: 75px;
	margin-left: 332px;
	@include bp(medium-screen) {
		margin-left: 277px;
	}
	@include bp(tablet) {
		margin-left: 0px;
		margin-top: 124px;
	}
}

@include bp(mobile) {
	.content-area {
		margin-left: 0px;
		padding-bottom: 0px !important;
	}
}
.section-container {
	padding: 0px 100px;
	@include bp(medium-screen) {
		padding: 0px 30px;
		&.card-container {
			padding: 0px 50px;
		}
	}
	@include bp(mobile) {
		padding: 0px 24px;
	}
	&.product-container {
		padding: 0px;
		@include bp(medium-screen) {
			padding: 0px;
		}
		@include bp(mobile) {
			padding: 0px;
		}
	}
	&.card-section-container {
		padding: 0px 60px 0 70px;
		@include bp(medium-screen) {
			padding: 0px 24px 0 34px;
		}
		@include bp(mobile) {
			padding:  0px 24px 0 34px;
		}
	}
	&.porsche-product-container {
		padding: 0px 180px;
		@include bp(medium-screen) {
			padding: 0px 24px 0 34px;
		}
		@include bp(mobile) {
			padding:  0px 24px 0 34px;
		}
	}
	.padding-100 {
		padding: 0px 100px;
		@include bp(medium-screen) {
			padding: 0px 30px;
		}
		@include bp(mobile) {
			padding: 0px 24px;
		}
	}
}
.hrseptop {
	padding-left: 50px;
	padding-right: 50px;
	@include bp(medium-screen) {
		padding-left: 30px;
		padding-right: 30px;
	}
	@include bp(mobile) {
		padding-left: 24px;
		padding-right: 24px;
	}
	hr {
		margin-top: 60px;
		@include bp(mobile) {
			margin-top: 40px;
			margin-bottom: 30px;
		}
	}
}
.pageContents h2{
	margin-bottom: 45px;
	@include bp(mobile) {
		margin-bottom: 30px;
	}
	& + .heading-5 {
		margin-bottom: 20px;
		& + div {
			.praghaph-3 {
				margin-bottom: 25px;
				a {
					color: #222 !important;
					font-weight: 600;
					text-decoration: underline;
				}	
			}	
		}
	}
}
.pageContents h2 {
	margin-bottom: 45px;
	@include bp(mobile) {
		margin-bottom: 30px;
	}
}
.pageContents .videoPlaybutton {
	@include bp(mobile) {
		margin-bottom: 15px;
	}
}
.pageContents h2 {
	margin-bottom: 45px;
	@include bp(mobile) {
		margin-bottom: 15px;
	}
}
.pageContents h2 {
	margin-bottom: 45px;
	@include bp(mobile) {
		margin-bottom: 15px;
	}
}
.pageContents h2 {
	margin-bottom: 45px;
	@include bp(mobile) {
		margin-bottom: 15px;
	}
	& + .heading-5 + div + .swipe-product {
		margin-top: 60px;
	}
}
.lr-paading-container {
	padding-left: 180px;
	padding-right: 180px;
	@include bp(medium-screen) {
		padding-left: 24px;
		padding-right: 24px;
	}
	@include bp(mobile) {
		padding-left: 24px;
		padding-right: 24px;
	}
	&.picture-gallery-border {
		border: none;
	}
	& ~ h2,& ~ h5,& ~ div p,& ~ .btm-acr-container {
		padding-left: 180px;
		padding-right: 180px;
		@include bp(medium-screen) {
			padding-left: 24px;
			padding-right: 24px;
		}
		@include bp(mobile) {
			padding-left: 24px;
			padding-right: 24px;
		}
	}
}
.porsche-principle-video {
	padding-left: 180px;
	padding-right: 40px;
	margin-top: 40px;
	@include bp(medium-screen) {
		padding-left: 24px;
		padding-right: 24px;
	}
	@include bp(mobile) {
		padding-left: 24px;
		margin-top: 10px;
		padding-right: 24px;
	}
	.event-detail {
		.headline, .praghaph {
			padding: 0;
			position: relative;
			top: -5px;
			@include bp(mobile) {
				top: unset;
			}
		}
		.headline {
			font-weight: 700;
		}
	}
	.event-sec {
		.event-img {
			.img-fluid {
				object-fit: cover;
				max-height: 200px;
				@include bp(mobile) {
					max-height: 150px;
				}
			}
		}
	}
}
.videoPlaybutton {
	padding-left: 180px;
	padding-right: 180px;
	padding-bottom: 30px;
	@include bp(medium-screen) {
		padding-left: 24px;
		padding-right: 24px;
	}
	@include bp(mobile) {
		padding-left: 24px;
		padding-right: 24px;
		padding-bottom: 0;
	}
	.video-icon {
		background: #E3E4E5;
		border-radius: 50%;
		color: #000000;
	}
}
.accordion {
	color: #7C7F81;
	.card {
		border: none;
		background: none;
		&:last-child {
			border-bottom: 1px solid #ccc;
		}
		.card-header {
			border-top: 1px solid #ccc;
			border-bottom: 0;
			color: #000000;
			font-weight: 700;
			background: none;
			margin-bottom: 0 !important;
			.collapse {
				border-top: 1px solid #ccc;
				&.show {
					border-top: 0;
				}
			}
			.col-10 {
				display: flex;
				align-items: center;
				position: relative;
				top: 3px;
				left: -5px;
				padding: 0;
				@include bp(mobile) {
					font-size: 16px !important;
				}
			}
		}
		.card-body {
			padding-left: 0px;
			padding-right: 0px;
			padding-top: 0;
			@include bp(mobile) {
				font-size: 16px !important;
			}
		}
	}
	.accr-btn-box {
		padding: 0;
		position: relative;
		right: -12px;
	}
}
.main-content-area {
	height: calc(100vh);
	padding-top: 88px;
    padding-bottom: 88px;
	margin-top: 0;
	margin-left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	@include bp(medium-screen) {
		margin-left: 0;
	}
	@include bp(tablet) {
		margin-top: 0;
		margin-left: 0px;
		padding-top: 0px;
		height: calc(100vh);
	}
	background: #f2f2f2;
	.pageContents {
		.section-container {
			position: relative;
			top: 0px;
			#dropdown-basic {
				border-radius: 0;
				border: 1px solid #767676;
				background: #fff;
				color: #666666;
				min-width: 200px;
				text-align: left;
				position: relative;
				&.dropdown-toggle::after {
					position: absolute;
					right: 10px;
					top: 15px;
				}
			}
			.btn-success:not(:disabled):not(.disabled).active:focus,
			.btn-success:not(:disabled):not(.disabled):active:focus,
			.show > .btn-success.dropdown-toggle:focus,
			.btn-success:focus {
				box-shadow: none;
			}
			.dropdown-menu {
				position: absolute;
				top: 37px !important;
				border-radius: 0;
				box-shadow: none;
				border: 1px solid #767676;
				border-top: none;
				left: 50% !important;
				max-width: 200px;
				min-width: 200px;
				transform: translate(-50%, 0) !important;
				.dropdown-item:focus,
				.dropdown-item:hover {
					color: #d5001c;
					text-decoration: none;
					background: none !important;
				}
			}
		}
	}
	.main-footer {
		position: fixed;
		width: 100%;
		bottom: 0;
	}
	@include bp(mobile) {
		padding-bottom: 45px !important;
	}
}
.select-main-nav {
	ul {
		li {
			a {
				color: #ffffff !important;
				i {
					img {
						width: 18px;
						height: auto;
						position: relative;
						top: -5px;
					}
				}
			}
			&:last-child {
				float: right;
			}
		}
	}
}

.card-1 {
	margin-left: 90px;
	margin-right: 192px;
	@include bp(medium-screen) {
		margin-left: 24px;
		margin-right: 142px;
	}
	@include bp(mobile) {
		margin-left: 24px;
		margin-right: 0px;
	}
}
.wilkomen-page .card-1 {
	margin-left: 60px;
	@include bp(medium-screen) {
		margin-left: 24px;
		margin-right: 142px;
	}
	@include bp(mobile) {
		margin-left: 24px;
		margin-right: 24px;
	}
}
.bieMenuPage {
	.card-1 {
		margin-left: 0px;
	}
	.accordion {
		.card {
			.collapse {
				img {
					width: 100%;
					padding-right: 100px;
					margin-top: 20px;
					@include bp(medium-screen) {
						padding-right: 0;
					}
				}
			}
		}
	}
	.videoPlaybutton {
		padding-left: 0px;
		padding-right: 180px;
		padding-bottom: 30px;
		@include bp(medium-screen) {
			padding-left: 0px;
			padding-right: 24px;
		}
		@include bp(mobile) {
			padding-left: 0px;
			padding-right: 24px;
			padding-bottom: 0;
		}
	}	
}
.benifitsAccr {
	.accordion {
		.card {
			.collapse {
				img {
					margin-top: 20px;
				}
			}
		}
	}
}
.bulletPoints {
	p {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		span {
			width: 20px;
			display: inline-block;
			&:nth-child(even) {
				width: calc(100% - 20px);
			}
		}
	}
}

.wilkomen-page {
	.headingmainpage {
		margin-bottom: 12px;
		@include bp(mobile) {
			margin-bottom: 15px;
		}
	}
}
.howwework-page .card-1 {
	margin-left: 60px;
	@include bp(medium-screen) {
		margin-left: 24px;
		margin-right: 142px;
	}
	@include bp(mobile) {
		margin-left: 24px;
		margin-right: 24px;
	}
}
.howwework-page {
	.headerParagraph {
		margin-bottom: 15px;
	}
}
.marg-60 {
	margin-top: 60px;
	line-height: 68px;
}
// .pos-img-01{
// 	// width: 65%;
// }
.pos-img-02 {
	width: 50%;
	position: absolute;
	bottom: 360px;
	right: 0px;
}
.principal-overview-buttons {
	a {
		font-size: 16px;
		color: #fff;
		text-decoration: none;
		background: #323639;
		display: inline-block;
	}
}

.pageContents {
	.principal-overview-buttons {
		margin: 0px 5px;
		&:first-child {
			a {
				background: #e00000;
			}
		}
	}
	.principal-buttons {
		margin: 80px 0px 80px 0px;
		&:last-child {
			display: none !important;
		}
		@include bp(mobile) {
			flex-direction: column;
			margin: 40px 0px 40px 0px;
			.principal-overview-buttons {
				margin-bottom: 10px;
				a {
					min-width: 215px;
				}
			}
		}
	}
	> div.principal-buttons:nth-child(7) {
		display: none !important;
	}
}

.mar-tp-100 {
	margin-top: 100px;
}
.slider-wrapper {
	margin-left: 0px !important ;
}
.grey-bg {
	background: #f2f2f2;
}
.control-arrow {
	display: none;
}

.pos-img-sec {
	.img-pos {
		position: relative;
		top: -70px;
		left: 0px;
		width: 100%;
		@include bp(mobile) {
			position: relative;
			top: 0;
			left: 0px;
		}
	}
}
.carousel .control-dots {
	padding-left: 0px;
	bottom: 30px !important;
}
.carousel .control-dots .dot {
	width: 62px !important;
	border-radius: 0px !important;
	height: 3px !important;
	opacity: 1 !important;
	box-shadow: none !important;
	background: #c9cacb !important;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
	background: #d5001c !important;
}
.carousel-status {
	display: none;
}

.team-img {
	width: 100px;
}
.team-member-dtl {
	padding-left: 0px;
	font-size: 16px;
	padding-right: 60px;
	.team-name-title {
		font-family: "PorscheNext-Bold";
		color: #7c7f81;
		display: block;
	}
	.team-sub-title {
		font-family: "PorscheNext-Regular";
		color: #7c7f81;
	}
}
.team-know-more {
	margin-left: 10px;
	margin-top: 10px;
	display: inline-block;
}
.read-more {
	font-size: 16px;
	color: #000000;
	text-decoration: underline;
	&:hover {
		color: #4a4e51;
	}
}
.marg-tp-50 {
	margin-top: 50px;
}
.mar-tp-bt-100 {
	margin-top: 100px;
	margin-bottom: 100px;
}
.docs-6 .PorscheTeam{
    margin-top: 20px;
}
// .docs-6 .PorscheTeam ~ div {
//     background-color: lightgrey;
// }
// .docs-6 .PorscheTeam + div {
//    padding-top: 1%;
// }
// .docs-6 .section-container div:nth-last-child(1){
// 	padding-bottom: 1%;
// }

.docs-6 .section-container div:nth-last-child(1){
	margin-bottom: 1%;
}
.perfect-start-sec {
	h2 {
		// font: 60px "PorscheNext-Bold";
		color: #fff;
		// line-height: 50px;
	}
	padding-bottom: 80px;
	@include bp(mobile) {
		padding-bottom: 0px;
		&.grey-bg {
			background-color: #fff;
		}
	}
}

// css utilities
.sec-wrap-margin-tp {
	margin-top: 95px;
	@include bp(mobile) {
		margin-top: 60px;
	}
}

// Support Page
.padding-left5 {
	padding-left: 5px !important;
}
.card-image-box {
	background: #f2f2f2;
	margin: 0 !important;
	padding-left: 0px;
	margin-top: 20px !important;
	flex-wrap: nowrap !important;
	.card-image {
		padding-right: 15px;
		.img-fluid {
			min-height: 130px;
			min-width: 130px;
			max-height: 130px;
			max-width: 130px;
			object-fit: cover;
			@include bp(mobile) {
				min-height: 80px;
				min-width: 80px;
				max-height: 80px;
				max-width: 80px;
			}
		}
	}
	.support-content-area {
		display: flex;
		align-items: center;
		padding-left: 15px;
		font-size: 18px;
		@include bp(mobile) {
			font-size: 16px;
		}
		a {
			cursor: pointer;
			display: flex;
			span {
				text-decoration: underline;
				margin-left: 10px;
			}
			&:hover {
				text-decoration: none;
			}
			i {
				position: relative;
				top: 6px;
				font-size: 12px;
				font-weight: 600;
			}
		}
	}
}
.support-bg-img {
	width: 100%;
	background-size: contain;
	object-fit: cover;
	margin-bottom: 40px;
	background-repeat: no-repeat;
	position: relative;
	@include bp(mobile) {
		margin-bottom: 0px;
	}
	.support-content-area {
		padding: 40px;
		padding-right: 40%;
		position: absolute;
		top: 0px;
		left: 0px;
		@include bp(medium-screen-desktop) {
			padding-right: 20%;
		}
		@include bp(medium-screen) {
			padding-right: 38%;
		}
		@include bp(mobile) {
			margin-top: 25px;
			margin-bottom: 25px;
		}
		.praghaph-bold {
			color: #d5001c;
			margin-bottom: 20px;
			display: block;
		}
		.heading-2 {
			margin-bottom: 0px;
			color: #fff;
			font-family: "PorscheNext-Bold";
			@include bp(mobile) {
				color: #000;
			}
		}
		.heading-4 {
			color: #fff;
			line-height: 36px;
			@include bp(mobile) {
				color: #000;
			}
		}
		.img-linktext {
			margin-left: 10px;
			text-decoration: underline;
			.icon-arrow-right {
				margin-right: 8px;
				color: #d5001c;
				font-size: 12px;
				display: inline-block;
			}
			span {
				text-decoration: underline;
			}
			&:hover {
				color: #fff;
			}
		}

		@include bp(mobile) {
			position: relative;
			padding: 0px;
			padding-top: 0px;
			.praghaph-bold {
				color: #d5001c;
				margin-bottom: 12px;
			}
			.heading-2 {
				margin-bottom: 10px;
				color: #000000;
			}
			.img-linktext {
				color: #000000;
			}
		}
	}
	.img-fluid {
		width: 100%;
	}
}

//mission section
.mission-sec {
	.heading-2 {
		font-family: "PorscheNext-Regular";
		color: #4a4e51;
	}
	margin-bottom: 40px !important;
}

//Footer image section
.footer-area {
	margin-bottom: 0px;
	@include bp(mobile) {
		margin-top: 70px;
	}
	.img-fluid {
		max-height: 620px;
		object-fit: cover;
	}
}
.footer-sec {
	margin-right: 20% !important;
	padding-right: 0px !important;
	top: 50% !important;
	transform: translateY(-50%);
	padding: 0px 100px !important;
	@include bp(medium-screen) {
		padding: 0px 30px !important;
	}
	@include bp(mobile) {
		top: 0% !important;
		transform: translateY(0%);
		padding: 0px 25px !important;
		margin-right: 0% !important;
		padding-bottom: 65px !important;
	}
	.heading-4 {
		color: #fff;
		font-family: "PorscheNext-Bold";
	}
	.heading-2 {
		color: #fff;
		font-family: "PorscheNext-Bold";
	}
	.anchor-button-red {
		display: inline-block;
	}
}
.Footerbackground {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	background-color: #000000;
	opacity: 0.5;
	z-index: 1;
}
.headingmainpage {
	margin-top: 50px;
	@include bp(mobile) {
		margin-top: 30px;
	}
} 
.accr-btn-box {
	padding: 0;
    display: flex;
    justify-content: flex-end;
	.accordionButton {
		padding: 0.375rem 0;
		span {
			transform: rotate(180deg);
			/* Legacy vendor prefixes that you probably don't need... */
			/* Safari */
			-webkit-transform: rotate(180deg);
			/* Firefox */
			-moz-transform: rotate(180deg);
			/* IE */
			-ms-transform: rotate(180deg);
			/* Opera */
			-o-transform: rotate(180deg);
			writing-mode: vertical-rl;
			color: #000000;
		}
		&:focus {
			box-shadow: none !important;
		}
	}
}
.hrfootersec {
	top: 0 !important;
	padding: 0px 60px !important;
	@include bp(mobile) {
		padding: 0px 24px !important;
	}
	display: flex;
	flex-wrap: wrap;
	position: absolute !important;
	height: 100%;
	z-index: 1;
	width: 100%;
	align-items: center;
	.top-footer {
		position: absolute;
		top: 12%;
	}
	.heading-4 {
		color: #fff;
		font-family: "PorscheNext-Bold";
	}
	.heading-2 {
		color: #fff;
		font-size: 64px;
		line-height: 64px;
		font-family: "PorscheNext-Bold";
		@include bp(mobile) {
			font-size: 24px;
			line-height: 32px;
		}
	}
	.footer-link {
		width: 100%;
		position: relative;
		i {
			position: absolute;
			right: 0;
			top: 50%;
			font-weight: 600;
			transform: translateY(-50%);
			font-size: 32px;
			color: #fff;
			@include bp(mobile) {
				font-size: 12px;
			}
		}
	}
}

//Your team section
.your-team-sec {
	margin-bottom: 30px;
	@include bp(mobile) {
		margin-bottom: 40px;
	}
	.your-team-img {
		margin-bottom: 28px;
		width: 100%;
	}
	.heading-4 {
		color: #000;
		margin-bottom: 0px;
	}
	p {
		font-size: 18px;
		color: #7c7f81;
		margin-bottom: 0px;
		line-height: 22px;
		word-break: break-all;
		@include bp(mobile) {
			font-size: 16px;
			line-height: 24px;
		}
	}
	.social-btn {
		display: inline-block;
		background: #f2f2f2;
		color: #323639;
		margin-right: 5px;
		min-width: 24px;
		min-height: 24px;
		text-align: center;
		margin-top: 10px;
		cursor: pointer;

		.social-icon {
			font-size: 10px;
		}
		.linkedin-icon {
			font-size: 12px;
		}
		&:hover {
			color: #d5001c;
		}
	}
}
.team-member-social-box {
	.social-btn {
		display: inline-block;
		background: #dddddd;
		color: #323639;
		margin-right: 5px;
		min-width: 24px;
		min-height: 24px;
		text-align: center;
		margin-top: 0px;
		cursor: pointer;
		a {
			margin-left: 0 !important;
		}
		.social-icon {
			font-size: 10px;
		}
		.linkedin-icon {
			font-size: 12px;
		}
		&:hover {
			color: #d5001c;
		}
	}
}
.download-app-sec {
	padding: 40px;
}
.app-download {
	margin-top: 90px;
}

.bor-tp-card {
	border-top: 1px solid #c9cacb;
	padding: 20px 0px;
	.digits {
		color: #D5001C;
		font-family: "PorscheNext-Bold";
		font-weight: 600;
	}
}

//just-say-sec

.pos-img-sec {
	margin-top: 190px;
	padding-bottom: 60px;
	background-color: #f2f2f2;
	@include bp(mobile) {
		margin-top: 0px;
		background-color: #fff;
	}
}
.just-say-sec {
	h2 {
		font-family: "PorscheNext-Bold";
		color: #000000;
		margin-top: 80px;
		margin-bottom: 0px;
		@include bp(mobile) {
			margin-top: 43px;
		}
	}
	.card-1 {
		margin: 0px;
		padding: 0px;
	}
	> div,
	a {
		margin-left: 25px;
		@include bp(mobile) {
			margin-left: 0px;
		}
	}
	.section-container {
		padding: 0px;
		margin-bottom: 25px;
	}
	.PorscheTeam {
		margin-top: 0px;
		flex-direction: column;
		margin-bottom: 0px;
	}
	.team-member-dtl {
		padding-right: 0px;
	}
	.media {
		margin-bottom: 15px;
	}
}

.PorscheTeam {
	margin-top: 40px;
	margin-bottom: 15px;
	display: flex;
	padding-left: 90px;
	@include bp(medium-screen) {
		padding-left: 44px;
	}
	@include bp(mobile) {
		padding-left: 0px;
		flex-direction: column;
		.meadia {
			margin-top: 15px;
		}
	}
}

//fantasy sec
.fantasy {
	h2 {
		// font-family: 'PorscheNext-Bold';
		// line-height: 68px;
		color: #000;
	}
	p {
		font-size: 28px;
		line-height: 36px;
		margin-bottom: 10px;
		@include bp(mobile) {
			font-size: 16px;
			line-height: 24px;
		}
	}
	.team-know-more {
		margin-top: 0px;
	}
	@include bp(mobile) {
		flex-direction: column;
	}
}

//event sec
.event-sec {
	margin-bottom: 25px;
	@include bp(mobile) {
		flex-direction: column;
		flex-direction: column-reverse;
		margin-bottom: 0px;
	}
	.event-img {
		@include bp(mobile) {
			flex-basis: auto;
			width: 100%;
		}
	}
	.headline-red {
		font-family: "PorscheNext-Bold";
		color: #d5001c;
		@include bp(mobile) {
			margin-bottom: 5px;
			line-height: 24px;
		}
	}
	.event-title {
		font: 32px "PorscheNext-Bold";
		margin-bottom: 0px;
		color: #000;
		@include bp(mobile) {
			font-size: 16px;
			margin-bottom: 15px;
		}
	}
	.event-detail {
		padding: 0px 50px 0 15px;
		@include bp(mobile) {
			padding: 30px 0px 15px 0;
			p {
				margin-bottom: 12px;
				&.praghaph {
					margin-bottom: 0;
				}
			}
		}

	}
	.event-pra {
		margin-bottom: 0px;
	}
}
//Footer sec

//header img sec
.content-header {
	position: relative;
	.img-fluid {
		width: 100%;
		max-height: 620px;
		object-fit: cover;
		@include bp(mobile) {
			min-height: 410px;
			position: relative;
		}
	}
}

//modal pop-up sec
.video-modal {
	iframe {
		border: 0px;
	}
}
.iframe-container {
	padding: 0 180px;
	@include bp(medium-screen) {
		padding-left: 24px;
		padding-right: 24px;
	}
	@include bp(mobile) {
		padding-left: 24px;
		padding-right: 24px;
	}
	background: rgba(216, 216, 216, 0.2);
	margin-bottom: 0 !important;
	.poll {
		background-color: transparent;
	}
}

//principle-imc-sec
.principle-imc-sec {
	margin-top: 70px;
	.img-fluid {
		width: 100%;
	}
}
.vertical-timeline {
	border-left: 3px solid #e3e4e5;
	padding-left: 60px;
	position: relative;
	padding-top: 30px;
	&.padding-top-50 {
		padding-top: 50px;
	}
	&:first-child {
		padding-top: 0;
		margin-top: 50px;
	}
	.timeline-head-icon {
		opacity: 0.9;
		margin-right: 15px;
		position: relative;
		top: -8px;
	}
	.timeline-content {
		padding-left: 30px;
		position: relative;
		.timeline-arrow {
			font-size: 14px;
			// color: #D5001C;
			position: absolute;
			left: 2px;
			top: 10px;
		}
	}
	.circle {
		width: 33px;
		height: 33px;
		border: 3px solid #e3e4e5;
		position: absolute;
		background: #fff;
		border-radius: 50%;
		left: -17px;
	}
	b {
		&.title {
			font-family: "PorscheNext-Bold";
			cursor: pointer;
		}
	}
	.title {
		display: inline-block;
		line-height: 1.2;
		text-decoration: underline;
		margin: 10px 0 5px;
	}
	.content {
		display: block;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
// carousel css
// .support-bg-img {
// 	&:active {
// 		.carousel-item {
// 			display: block;
// 		}
// 	}
// }
.product-modal {
	.modal-dialog {
		@media only screen and (min-width: 1280px) {
			width: 70vw;
			max-width: 70vw;
		}
		@media only screen and (max-width: 768px) {
			margin: 0.5rem auto;
		}
		@media only screen and (min-width: 668px) and (max-width: 1080px) {
			width: 65vw;
			max-width: 65vw;
		}
		width: 85vw;
		max-width: 85vw;
		.product-modal-content {
			padding: 30px;
			.section-container {
				// width: 100%;
				// float: right;
				.carousel-control-next,
				.carousel-control-prev {
					position: absolute;
					top: unset;
					bottom: -5px;
					z-index: 1;
					display: -ms-flexbox;
					display: flex;
					height: 20px;
					-ms-flex-align: center;
					align-items: center;
					-ms-flex-pack: center;
					justify-content: center;
					width: 30px;
					color: #fff;
					text-align: center;
					opacity: 0.5;
					transition: opacity 0.15s ease;
					@media only screen and (min-width: 1080px) {
						top: 0px;
					}
				}
				.carousel-control-prev {
					left: 0;
				}
				.carousel-control-next {
					right: 0;
				}
				.carousel-control-next-icon {
					background-image: url("./images/right.svg");
				}
				.carousel-control-prev-icon {
					background-image: url("./images/left.svg");
				}
				.carousel-indicators .active {
					opacity: 1;
					background-color: #d5001c;
				}
			}
			.text-title-section {
				color: #000000;
				.timeline-head-icon {
					opacity: 0.9;
					margin-right: 15px;
					position: relative;
					top: -8px;
				}
			}
			@media (max-width: 1080px) {
				padding-top: 100px;
				padding-bottom: 50px;
				.section-container {
					padding: 0px;
					margin-bottom: 20px;
				}
				.discription-section {
					position: static;
					.text-title-section {
						position: absolute;
						top: 30px;
					}
				}
			}
			@media only screen and (min-width: 1080px) {
				display: flex;
				align-items: flex-start;
				.section-container {
					padding: 0;
					padding-right: 30px;
					max-width: 70%;
					min-width: 70%;
					.carousel-control-next,
					.carousel-control-prev {
						position: absolute;
						top: 0;
						right: calc(-50% + 20px);
						z-index: 1;
						display: -ms-flexbox;
						display: flex;
						-ms-flex-align: center;
						align-items: center;
						-ms-flex-pack: center;
						justify-content: center;
						width: 30px;
						color: #fff;
						text-align: center;
						opacity: 0.5;
						transition: opacity 0.15s ease;
					}
					.carousel-control-prev {
						left: unset;
					}
					.carousel-control-next {
						right: -50%;
					}
					// .carousel-inner {
					// 	.carousel-item {
					// 		img {
					// 			max-height: 380px;
					// 			min-height: 380px;
					// 		}
					// 	}
					// }
				}
				.discription-section {
					max-width: 30%;
					min-width: 30%;
					height: 100%;
					overflow-y: auto;
					.text-title-section {
						margin-bottom: 40px;
						width: 100%;
					}
				}
			}
		}
	}
}
.swipe-image-modal {
	.modal-dialog {
		@media only screen and (min-width: 1280px) {
			width: 60vw;
			max-width: 60vw;
		}
		width: 85vw;
		max-width: 85vw;
		.modal-content {
			img {
				width: 100%;
			}
		}
	}
}
.swipe-product {
	background: rgba(216, 216, 216, 0.2);
	margin: 150px 0 0px 0;
	padding: 0px 0 0 180px;
	@include bp(medium-screen) {
		padding-left: 24px;
		padding-right: 24px;
	}
	@include bp(mobile) {
		padding-left: 24px;
		padding-right: 24px;
		margin: 50px 0 0px 0;
	}
	.carousel {
		padding: 50px 0 100px 0;
		.slider-wrapper {
			ul {
				.slide {
					min-width: 90%;
					padding: 15px;
					@media screen and (max-width: 1080px) {
						min-width: 95% !important;
						padding: 10px;
					}
					@media screen and (max-width: 768px) {
						min-width: 100% !important;
						padding: 5px;
					}
					.swipe-section {
						opacity: 0.3;
						.text-section {
							p {
								padding: 0;
							}
						}
					}
					&.selected {
						.swipe-section {
							opacity: 1;
						}
					}
					.swipe-section {
						padding: 0px;
						display: flex;
						height: 100%;
						background: #ffffff;
						border: 1px solid #cccccc;
						.content-box:nth-child(2) {
							margin-left: 20px;
							width: 50%;
							background: #e5e5e5;
						}
						.image-attached {
							background: none !important;
							@media screen and (min-width: 1080px) {
								.img-content {
									height: 100%;
									img {
										height: 100%;
										min-height: 489px;
										max-height: 489px;
									}
								}
							}
							@media screen and (max-width: 1080px) {
								.img-content {
									img {
										height: 100%;
										max-height: 155px;
									}
								}
							}
						}
						.text-section {
							border-top: 0px solid #7c7f81;
							width: 50%;
							text-align: left;
							padding: 50px 20px 20px 20px;
							.name {
								margin: 10px 0;
							}
							.heading {
								margin-bottom: 20px;
							}
							.user-details {
								display: flex;
								align-items: center;
								.user-logo {
									width: 50px;
									height: 50px;
									padding: 2px;
									border: 1px solid #d5001c;
									border-radius: 50%;
									margin-right: 15px;
									img {
										width: 100%;
										height: 100%;
										min-width: 44px;
										min-height: 44px;
										border-radius: 50%;
									}
								}
								.user-name {
									width: auto;
									padding-left: 0;
									span {
										display: block;
										&.name {
											font-family: "PorscheNext-Bold";
											margin: 0;
											margin-bottom: 5px;
										}
									}
								}
							}
						}
						.image-section {
							.img-content {
								position: relative;
								.expand-icon {
									position: absolute;
									bottom: 15px;
									right: 15px;
									cursor: pointer;
									z-index: 2;
									width: 18px;
									height: 18px;
								}
								img {
									width: 100%;
    								object-fit: cover;
								}
							}
						}
						@media (max-width: 1080px) {
							display: block;
							float: left;
							.image-section {
								width: 100% !important;
								float: left;
								margin-top: 20px;
								margin-left: 0px !important;
							}
							.text-section {
								width: 100%;
								float: right;
							}
						}
						@include bp(mobile) {
							background: none;
							border: none;
							display: flex;
							flex-wrap: wrap;
							height: auto;
							flex-direction: column-reverse;
							.text-section {
								padding: 20px 0 0 0;
								.description {
									margin-bottom: 0;
								}
							}
							.image-attached {
								margin-top: 0;
								.img-content {
									height: auto;
									img {
										height: 100%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
.verschiedene {
	padding: 0 73px;
	margin-top: 60px;
	@include bp(medium-screen) {
		padding-left: 24px;
		padding-right: 24px;
	}
	@include bp(mobile) {
		padding-left: 24px;
		padding-right: 24px;
		.col-12 {
			padding: 15px;
			&:first-child {
				padding-top: 0px;
			}
			&:last-child {
				padding-bottom: 0px;
			}
		}
	}	
	.row {
		margin: 0;
	}
}
.paraNames {
	margin-right: 60px;
	@include bp(medium-screen) {
		margin-left: 24px;
		margin-right: 24px !important;
	}
	@include bp(mobile) {
		margin-left: 24px;
		margin-right: 24px;
	}
	.verschiedene {
		margin: 0 !important;
		margin-bottom: 40px !important;
		padding: 0;
		flex-wrap: nowrap;
		@include bp(mobile) {
			flex-wrap: wrap;
		}
		.justify-content-center {
			justify-content: flex-start !important;
			color: #7C7F81 !important;
			font-size: 18px !important;
			line-height: 30px;
			font-weight: normal !important;
		}
		.text-center {
			padding-left: 0;
			padding-right: 50px;
			text-align: left !important;
			&:last-child {
				padding-right: 0;
			}

		}
		.col-md-4 {
			width: auto;
			min-width: unset;
			max-width: unset;
			flex: unset;
			white-space: normal;
			@include bp(mobile) {
				width: 100%;
				white-space: nowrap;
			}
		}
	}
}
.feedbackPage {
	padding: 0;
	padding-bottom: 75px;
	@include bp(mobile) {
		padding-bottom: 0px;
	}
	.headingmainpage {
		margin: 0;
	}
	.feedbackBackground {
		padding-left: 180px;
		padding-right: 180px;
		@include bp(medium-screen) {
			padding-left: 24px;
			padding-right: 24px;
		}
		@include bp(mobile) {
			padding-left: 24px;
			padding-right: 24px;
		}
		&:first-child {
			margin-top: 55px;
		}
		&:nth-child(2) {
			margin-bottom: 50px;
		}
		&:nth-child(3)
		{
			padding-top: 50px;
			background: rgba(216, 216, 216, 0.2);
		}
		&:nth-child(4) {
			padding-top: 25px;
			padding-bottom: 50px;
			margin: 0;
			background: rgba(216, 216, 216, 0.2);
			p {
				margin-bottom: 0;
			}
		}
		.media {
			display: flex;
			align-items: center;
			.linkemail {
				text-decoration: underline;
				font-family: "PorscheNext-Regular";
			}
			img {
				margin-right: 14px;
			}
		}
	}
}
.feedback-frame {
	padding: 0px 180px 50px 180px;
	width: 100%;
	background: #f7f7f7;
	border: none;
	height: 550px;
	margin: 0;
	@include bp(medium-screen) {
		padding-left: 24px;
		padding-right: 24px;
	}
	@include bp(mobile) {
		padding-left: 24px;
		padding-right: 24px;
	}	
	.survey-view {
		background: none;
	}
}
.cubeSpinner {
	background: #f7f7f7;
	margin-top: 45px;
	display: flex;
	flex-wrap: wrap;
	.swipe-data-center:first-child {
		margin-top: 45px !important;
	}
}
.principle-footer {
	.footer-area {
		margin-top: 0;
	}
}
.main-initative {
	display: flex;
	flex-wrap: wrap;
	margin-top: 30px;
	margin-right: 0 !important;
	.porsche-mission-vision-sec {
		padding-left: 0;
		:last-child {
			padding-right: 0;
		}
	}
}

.porsche-mission-vision-sec {
	.heading-2 {
		font-family: "PorscheNext-Bold";
	}
	p {
		@include bp(medium-screen) {
			padding-top: 0px;
		}
		@include bp(mobile) {
			padding-top: 20px;
		}
	}
	img {
		width: 100%;
	}
	.porsche-mission-vision-content {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		@include bp(medium-screen) {
			position: relative;
			top: initial;
			transform: none;
		}
	}
}
.msn-sec {
	margin-bottom: 40px;
}

// picture gallery
.picture-gallery {
	margin-top: 50px;
	margin-bottom: 50px;
	img {
		@include bp(mobile) {
			margin-bottom: 25px;
		}
	}
	.img-fluid {
		width: 100%;
	}
}
.picture-gallery-border {
	border: 1px solid #c9cacb;
	border-left: 0px;
	border-right: 0px;
	margin: 70px 0px 40px 0px;
	.img-fluid {
		width: 100%;
		max-height: 407px;
		object-fit: cover;
		@include bp(mobile) {
			max-height: 180px;
		}
	}
	@include bp(mobile) {
		margin: 30px 0px;
	}
}
.pageContents h6 {
	margin-left: 90px;
}
.smallheading {
	margin-left: 0 !important;
	color: #7C7F81;
	font-weight: 600;
}
.paddingtop60 {
	padding-top: 60px !important;
}
//our-values-sec
.our-values-sec {
	margin-top: 50px;
	.row {
		.col-12 {
			&:last-child {
				.bor-tp-card {
					border-bottom: 1px solid #c9cacb;
				}
				margin-bottom: 25px;
			}
		}
	}
}

// need-help-sec

.need-help-sec {
	background: #f2f2f2;
	padding-top: 105px;
	padding-bottom: 105px;
	@include bp(mobile) {
		padding-top: 80px;
		padding-bottom: 80px;
		margin-bottom: -74px !important;
	}
}
.need-help-sec {
	.heading-4 {
		color: #7c7f81;
	}
	margin-top: 115px;
	margin-bottom: -100px;

	.heading-3 {
		font: 60px "PorscheNext-Bold";
		line-height: 68px;
		@include bp(mobile) {
			font-size: 24px;
			line-height: 32px;
		}
	}
	p {
		font-size: 28px;
		line-height: 1.2;
		margin-bottom: 0.5rem;
		@include bp(mobile) {
			font-size: 16px;
			line-height: 24px;
		}
	}
	.card-1 {
		margin-left: 0px;
		margin-right: 0px;
	}
}

// table-value-sec
.table-value-sec {
	margin-top: 40px;
	margin-bottom: 40px;
}

.list-area {
	list-style: none;
}
.label-bold {
	font-family: "PorscheNext-Bold";
}
.dash-line {
	&:before {
		content: "-";
		margin-right: 10px;
	}
}

/*---------------------footer-btn--------------------*/

.btn-animation {
	background-color: #d5001c;
	border: none;
	color: white;
	padding: 9px 27px !important;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	transition-duration: 0.4s;
	cursor: pointer;
	color: white;
	position: relative;
	z-index: 1;
	display: inline-block;
}

.btn-animation span {
	display: inline-block;
	margin-left: 55px;
	line-height: 31px;
}

//   .btn-animation:hover {
// 	background: #E90B28;
// 	box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.41);
//   }

.book {
	transform-style: preserve-3d;
	position: relative;
	height: 17px;
	cursor: pointer;
	-webkit-backface-visibility: visible;
	backface-visibility: visible;
	display: inline-block;
	vertical-align: top;
	top: 18px;
	left: 18.5px;
}

.front,
.back,
.page1,
.page2,
.page3,
.page4,
.page5,
.page6 {
	transform-style: preserve-3d;
	position: absolute;
	width: 15px;
	height: 100%;
	top: 0;
	left: 0;
	transform-origin: left center;
	transition: transform 0.5s ease-in-out, box-shadow 0.35s ease-in-out;
}

.front,
.back {
	background: #a40016;
	border: 1px solid #51000b;
}

.front,
.page1,
.page3,
.page5 {
	border-bottom-right-radius: 0.2em;
	border-top-right-radius: 0.2em;
}

.back,
.page2,
.page4,
.page6 {
	border-bottom-right-radius: 0.2em;
	border-top-right-radius: 0.2em;
}

.page1 {
	background: #efefef;
}

.page2 {
	background: #efefef;
}

.page3 {
	background: #eeeeee;
}

.page4 {
	background: #f5f5f5;
}

.btn-animation:hover .page2 {
	transform: rotateY(-60deg) scale(1.1);
	box-shadow: 0 0.1em 1em 0 rgba(0, 0, 0, 0.6);
}

.btn-animation:hover .page3 {
	transform: rotateY(-0deg) scale(1.1);
	box-shadow: 0 0.1em 1em 0 rgba(0, 0, 0, 0.1);
}

.btn-animation:hover .page4 {
	transform: rotateY(-0deg) scale(1.1);
	box-shadow: 0 0.1em 1em 0 rgba(0, 0, 0, 0.1);
}

/*
  .btn:hover .back {
	transform: rotateY(0deg) scale(1.1);
  } */

.btn-animation:focus {
	outline: none;
}

.btn-animation:focus .page2 {
	transform: rotateY(-160deg) scale(1.1);
}

.btn-animation:focus .page2:after {
	display: none;
}

/*------------------*/
.btn-animation .front {
	transform: rotateY(-160deg) scale(1.2);
}

.btn-animation .page1 {
	transform: rotateY(-160deg) scale(1.1);
}

.btn-animation .page2 {
	transform: rotateY(-0deg) scale(1);
	/* background: #B1AFAF; */
}

.btn-animation .page2:after {
	content: "";
	position: absolute;
	left: 0px;
	top: 0px;
	width: 1px;
	background: #51000b;
	height: 17px;
}

.btn-animation .page2:before {
	content: "";
	position: absolute;
	left: 0px;
	top: 4px;
	width: 70%;
	height: 10px;
	background: url(../src/images/book-text.png) no-repeat;
	background-size: 100%;
	left: 3px;
}

.btn-animation .page3:before {
	content: "";
	position: absolute;
	left: 0px;
	top: 4px;
	width: 70%;
	height: 10px;
	background: url(../src/images/book-text.png) no-repeat;
	background-size: 100%;
	left: 3px;
}

.btn-animation .page3 {
	transform: rotateY(-0deg) scale(1);
}

.btn-animation .page3:after {
	content: "";
	position: absolute;
	left: 0px;
	top: 0px;
	width: 1px;
	background: #51000b;
	height: 17px;
}

.btn-animation .page4 {
	transform: rotateY(-0deg) scale(1.1);
}

.btn-animation .back {
	transform: rotateY(-0deg) scale(1.2);
}

/*---------person-----------*/
.person {
	position: absolute;
	width: 22px;
	left: 35px;
	top: 4px;
}

.person img {
	width: 100%;
}
