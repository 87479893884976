@import "./../../css/font.scss";
@import "./../../css/mixins.scss";
@import "./../../css/typography.scss";



.login-reset-text {
    span {
        color: #7C7F81;
    }
    a{
        color: #000000;
        text-decoration: underline;
    }
    @include bp(mobile) {
        text-align: left;
    }
}